import BaseModel from "./BaseModel";
import Image from "./Image";
import AccommodationContact from "./AccommodationContact";
import AccommodationUnit from "./AccommodationUnit";

export default class AccommodationRoom extends BaseModel {

    private _id: number = -1;
    private _title: string = "";
    private _small_description: string = "";
    private _type: string = "";
    private _id_unit: number = 0;
    private _beds: Array<{ type: string, count: number }> = [];
    private _equipment: Array<string> = [];
    private _images: Image[] = [];

    public isNew(): boolean {
        return !(this.id > 0);
    }


    public fromObject(jsonObj: object): BaseModel {
        return super.fromObject(jsonObj, {
            images: (item => {
                return (new Image()).fromObject(item);
            })
        });
    }

    public getAvailableBedsCount(type: string | null = null) {
        return this.beds.reduce((currentValue, bed) => {
            return currentValue + ((bed.type === type || type === null) ? bed.count : 0);
        }, 0);
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get id_unit(): number {
        return this._id_unit;
    }

    set id_unit(value: number) {
        this._id_unit = value;
    }

    get beds(): Array<{ type: string; count: number }> {
        return this._beds;
    }

    set beds(value: Array<{ type: string; count: number }>) {
        this._beds = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get equipment(): Array<string> {
        return this._equipment;
    }

    set equipment(value: Array<string>) {
        this._equipment = value;
    }


    get small_description(): string {
        return this._small_description;
    }

    set small_description(value: string) {
        this._small_description = value;
    }

    get notHiddenImages(): Image[] {
        return this.images.filter(i => i.hidden === false);
    }

    get images(): Image[] {
        return this._images;
    }

    set images(value: Image[]) {
        this._images = value;
    }

    public hasImage(includeHidden = true) {
        return includeHidden ? this.images.length > 0 : this.notHiddenImages.length > 0;
    }

    public getTopImage() {
        return this.notHiddenImages.length > 0 ? this.notHiddenImages[0] : this.images[0];
    }
}

