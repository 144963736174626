import BaseModel from "./BaseModel";
import AccommodationUnit from "./AccommodationUnit";
import dayjs from "dayjs";

export default class AccommodationUnitPriceLevel extends BaseModel {
    private _id: number = -1;
    private _last_minute: boolean = false;
    private _date_from: { day: number, month: number } = {day: 1, month: 1};
    private _date_to: { day: number, month: number } = {day: 1, month: 1};
    private _duration_type: string = 'object';
    private _curr_prices: { [k: string]: number } = {}
    private _curr_prices_weekend: { [k: string]: number } = {}
    private _price_for: string = '';
    private _season: string = '';
    private _note: string = '';
    private _title: string = '';
    private _min_nights: number = 1;
    private _min_hosts: number = 1;
    private _unit: AccommodationUnit = new AccommodationUnit();

    public durationInDays(){
        const dateFrom = dayjs(`2020-${this.date_from.month}-${this.date_from.day}`).minute(0).hour(0).second(0).millisecond(0);
        const dateTo = dayjs(`2020-${this.date_to.month}-${this.date_to.day}`).minute(0).hour(0).second(0).millisecond(0);
        return dateTo.diff(dateFrom, 'day')
    }

    get curr_prices(): { [p: string]: number } {
        return this._curr_prices;
    }

    set curr_prices(value: { [p: string]: number }) {
        this._curr_prices = value;
    }


    get curr_prices_weekend(): { [p: string]: number } {
        return this._curr_prices_weekend;
    }

    set curr_prices_weekend(value: { [p: string]: number }) {
        this._curr_prices_weekend = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get last_minute(): boolean {
        return this._last_minute;
    }

    set last_minute(value: boolean) {
        this._last_minute = value;
    }

    get date_from(): { day: number; month: number } {
        return this._date_from;
    }

    set date_from(value: { day: number; month: number }) {
        this._date_from = value;
    }

    get date_to(): { day: number; month: number } {
        return this._date_to;
    }

    set date_to(value: { day: number; month: number }) {
        this._date_to = value;
    }

    get duration_type(): string {
        return this._duration_type;
    }

    set duration_type(value: string) {
        this._duration_type = value;
    }

    get price_for(): string {
        return this._price_for;
    }

    set price_for(value: string) {
        this._price_for = value;
    }

    get season(): string {
        return this._season;
    }

    set season(value: string) {
        this._season = value;
    }

    get unit(): AccommodationUnit {
        return this._unit;
    }

    set unit(value: AccommodationUnit) {
        this._unit = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get min_nights(): number {
        return this._min_nights;
    }

    set min_nights(value: number) {
        this._min_nights = value;
    }

    get min_hosts(): number {
        return this._min_hosts;
    }

    set min_hosts(value: number) {
        this._min_hosts = value;
    }


    get note(): string {
        return this._note;
    }

    set note(value: string) {
        this._note = value;
    }
}
