import BaseModel from "./BaseModel";
import Image from "./Image";
import AccommodationRoom from "./AccommodationRoom";
import AccommodationUnitPriceLevel from "./AccommodationUnitPriceLevel";
import item from "../../components/admin/accommodation/index/list/item.vue";
import room from "../../components/admin/chat/room.vue";

export default class AccommodationUnit extends BaseModel {

    private _id: number = -1;
    private _title: string = "";
    private _small_description: string = "";
    private _description: string = "";
    private _duplicate_count: number = 0;
    private _hidden_for_reservation: boolean = false;
    private _duplicate_ids: number[] = [];
    private _images: Image[] = [];
    private _price_levels: Array<AccommodationUnitPriceLevel> = [];
    private _last_minutes: Array<Object> = [];
    private _rooms: AccommodationRoom[] = [];
    private _equipment: Array<{ [k: string]: Array<string> }> | {} = {};


    public fromObject(jsonObj: object): BaseModel {
        return super.fromObject(jsonObj, {
            images: (item => {
                return (new Image()).fromObject(item);
            }),
            rooms: (item => {
                return (new AccommodationRoom()).fromObject(item);
            }),
            price_levels: (item => {
                return (new AccommodationUnitPriceLevel()).fromObject(item);
            }),
        });
    }

    public getTotalNumberOfGuests(type: string | null = null) {
        return this.rooms.reduce((num, room) => {
            let count = 0;
            for (let roomBed of room.beds) {
                if (roomBed.count > 0 && (type === null || type === roomBed.type)) {
                    if (roomBed.type === 'double' || roomBed.type === 'twin' || roomBed.type === 'bunk') {
                        count += roomBed.count * 2;
                    } else {
                        count += roomBed.count;
                    }
                }
            }
            return num + count;
        }, 0)
    }

    public hasImage(includeHidden = true) {
        return includeHidden ? this.images.length > 0 : this.notHiddenImages.length > 0;
    }

    public getTopImage() {
        return this.notHiddenImages.length > 0 ? this.notHiddenImages[0] : this.images[0];
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get equipment(): Array<{ [p: string]: Array<string> }> | {} {
        return this._equipment;
    }

    set equipment(value: Array<{ [p: string]: Array<string> }> | {}) {
        this._equipment = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get small_description(): string {
        return this._small_description;
    }

    set small_description(value: string) {
        this._small_description = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get notHiddenImages(): Image[] {
        return this.images.filter(i => i.hidden === false);
    }

    get images(): Image[] {
        return this._images;
    }

    set images(value: Image[]) {
        this._images = value;
    }


    get rooms(): AccommodationRoom[] {
        return this._rooms;
    }

    set rooms(value: AccommodationRoom[]) {
        this._rooms = value;
    }

    get is_duplicated(): boolean {
        return this._duplicate_count > 0;
    }


    get duplicate_count(): number {
        return this._duplicate_count;
    }

    set duplicate_count(value: number) {
        this._duplicate_count = value;
    }

    get price_levels(): Array<Object> {
        return this._price_levels;
    }

    set price_levels(value: Array<Object>) {
        this._price_levels = value;
    }

    get last_minutes(): Array<Object> {
        return this._last_minutes;
    }

    set last_minutes(value: Array<Object>) {
        this._last_minutes = value;
    }

    get duplicate_ids(): number[] {
        return this._duplicate_ids;
    }

    set duplicate_ids(value: number[]) {
        this._duplicate_ids = value;
    }


    get hidden_for_reservation(): boolean {
        return this._hidden_for_reservation;
    }

    set hidden_for_reservation(value: boolean) {
        this._hidden_for_reservation = value;
    }
}
